/**
 * @file
 *
 * Customization script for the 'pam/student' form.
 */

import $ from 'jquery'
import moment from 'moment'
import { DATE_FORMAT } from '../../../../common'

$(document).ready(() => {
  const $forename = $('[name=etunimet]')
  const $nickname = $('[name=kutsumanimi]')
  $forename.on('blur', () => {
    if ($forename.val()) {
      $nickname.val($forename.val().split(' ')[0])
    }
  })

  const $startdate = $('[name=opiskelu_alkupvm]')
  const $joindate = $('[name=liittymispaiva]')
  $startdate.on('blur', () => {
    if ($startdate.val()) {
      const today = moment()
      const start = moment($startdate.val(), DATE_FORMAT)
      const format = start.creationData().format
      $joindate.val(start.isBefore(today) ? today.format(format) : start.format(format))
      $joindate.trigger('blur')
    }
  })
})
